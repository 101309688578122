<template>
  <v-app class="mt-0 pt-0">
    <h4>
      {{
        $getVisibleNames(
          "teaching.mattersessionplanning",
          false,
          "Planificación de la Asignatura"
        )
      }}
      Articulada
    </h4>
    <!-- {{ $store.state.sections }}.. -->
    <b-row>
      <b-col sm="6" lg="4">
        <MatterList
          :level="level"
          :period_id="period_id"
          :career_id="career_id"
          :modality_id="modality_id"
          :allows_crud="allows_crud"
          @update_section_list="slotUpdateSectionList"
          @changed_egress_profile="slotChangeEgressProfile"
        ></MatterList>
      </b-col>
      <b-col sm="6" lg="8">
        <Calendar :sections="sections" :allows_crud="allows_crud"></Calendar>
      </b-col>
    </b-row>
    <template v-if="selected_egress_profile_id">
      <MeshCustomFab
        :egress_profile_id="selected_egress_profile_id"
      ></MeshCustomFab>
    </template>
  </v-app>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "SchedulerView",
  components: {
    Calendar: () => import("./Calendar"),
    MatterList: () => import("./MatterList"),
    MeshCustomFab: () => import("@/components/mesh/MeshCustomFab"),
  },
  data() {
    return {
      periods: [],
      sections: [],
      allows_crud: true,
      selected_egress_profile_id: null,
      career_id: 1,
      period_id: this.$store.getters.getInstitution.actual_period.id,
      modality_id: 1,
      level: 1,
    };
  },
  computed: {
    ...mapGetters({
      careers: names.CAREERS,
      cycles: names.CYCLES,
      faculties: names.FACULTIES,
      permissions: "hasPermission",
    }),
    careerList() {
      return this.careers;
    },
  },
  methods: {
    fetchPeriods() {
      this.$restful
        .Get(
          `/mesh/period/?institution=${this.$store.getters.getInstitutionId}`
        )
        .then((response) => {
          this.periods = response;
        });
    },
    slotUpdateSectionList(section_list) {
      this.sections = section_list;
    },
    slotChangeEgressProfile(egress_profile_id) {
      this.selected_egress_profile_id = egress_profile_id;
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.allows_crud = this.$store.getters.hasPermission(
      "teaching.add_section"
    );
    if (this.$route.query.career_id) {
      this.career_id = Number(this.$route.query.career_id);
    }
    if (this.$route.query.period_id) {
      this.period_id = Number(this.$route.query.period_id);
    }
    if (this.$route.query.modality_id) {
      this.modality_id = Number(this.$route.query.modality_id);
    }
    if (this.$route.query.level) {
      this.level = Number(this.$route.query.level);
    }
  },
};
</script>

<style scoped>
.scheduler-container {
  padding: 1px;
  margin: 1px;
}
</style>